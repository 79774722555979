<template>
  <page-container3>
    <for-editor
      v-model:value="value"
      :onSave="save"
    ></for-editor>

    <a-button
      v-if="is_upload"
      type="primary"
      @click="upload"
      style="margin-right: 3px;margin-top: 5px"
    >
      {{ t('上传附件') }}
    </a-button><br/>
    <template v-for="(obj, obj_index) in appendix_json_list" :key="obj_index">
    <a-button
      type="link"
      @click="openUrl(obj)"
      style="margin-right: 3px"
    >
      {{ obj.name }}
    </a-button>
      <delete-outlined @click="delete_notice(obj)" style="color: #ff0000"/>
      <br/>
    </template>
<!--    <page-model-4-->
<!--      :modalType="scgyfangan.modalType"-->
<!--      :search="scgyfangan.search"-->
<!--      :title="scgyfangan.title"-->
<!--      :column_flag="scgyfangan.column_flag"-->
<!--      :height="600"-->
<!--      :is_search="false"-->
<!--      :mode="scgyfangan.mode"-->
<!--    />-->
  </page-container3>
</template>
<script>
import { createVNode, defineComponent, reactive, ref } from 'vue';
import ForEditor from '@/components/for-editor';
import { useStore } from 'vuex';
import { query_notice_list, update_notice } from '@/api/baseinfo-model';
import { message, Modal, notification } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import modalBox from '@/components/form-modal/modal-tools';
import { DeleteOutlined } from '@ant-design/icons-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-svg';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    ForEditor,
    DeleteOutlined,
    ExclamationCircleOutlined,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const notice_id = Number(route.params['id']);
    // let title = route.params['title'];
    const is_upload = ref(false);
    // route.meta.title = title;
    // watchEffect(() => {
    //   if (route.path.split('/')[2] == 'baseinfo-notice') {
    //     route.meta.title = title;
    //   }
    // });
    const value = ref(`# ${t('标题')}
#### ${t('公告内容')}`);
    const appendix_json_list = ref([]);
    let appendix_ids = [];
    const search_notice_list = () => {
      query_notice_list({
        org_id: current_org.id,
        ids: notice_id,
      }).then((res) => {
        if (res && res.data && res.data.length > 0) {
          if (res.data[0].content) {
            value.value = res.data[0].content;
          }
          if(res.data[0].appendix_json_list && res.data[0].appendix_json_list.length > 0){
            appendix_json_list.value = res.data[0].appendix_json_list;
            appendix_ids = res.data[0].appendix_ids.split(',');
          }
          if(res.data[0].notice_type == 1){
            is_upload.value = true;
          }
        }
      })
    }
    search_notice_list();
    const search = reactive({
      current: 1,
      pageSize: 50,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        notice_id: notice_id,
        is_active: true,
      },
    });
    const scgyfangan = reactive({
      title: t('公告阅读记录'),
      column_flag: 'notice_record',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'baseinfo_notice_record',
      mode: 'null',
    });
    const openUrl = (record) => {
      if (record.file_url) {
        const url =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    }
    const upload = () => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传附件'),
        group: '重要信息附件',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload) => {
          return new Promise(resolve => {
            loading.value = true;
            appendix_ids.push(payload);
            update_notice({
              notice_id: notice_id,
              appendix_ids: appendix_ids.join(','),
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                search_notice_list();
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    }
    const save = (data) => {
      update_notice({
        org_id: current_org.id,
        notice_id: notice_id,
        content: value.value,
      })
        .then(() => {
          message.success(t('保存成功'));
        })
    }
    const delete_notice = (record) => {
      Modal.confirm({
        title: t('是否删除?'),
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            let appendix_ids = [];
            appendix_json_list.value.map((item) => {
              if (record.file_id !== item.file_id) {
                appendix_ids.push(item.file_id);
              }
            });
            update_notice({
              notice_id: notice_id,
              appendix_ids: appendix_ids,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('删除成功'));
                search_notice_list();
                resolve(null);
              }).catch(() => console.log('Oops errors!'));
          })
        },
        onCancel() {},
      })

    }
    return {
      value,
      t,
      save,
      upload,
      scgyfangan,
      openUrl,
      delete_notice,
      is_upload,
      appendix_json_list,
    };
  },
});
</script>
